<nav aria-label="breadcrumb" class="px-3 pt-3 mb-0" *ngIf="breadcrumbs?.length">
    <ol class="breadcrumb">
        <li
                *ngFor="let crumb of breadcrumbs; let last = last"
                class="breadcrumb-item"
                [ngClass]="{ active: last }"
        >
            <!-- If not the last item and a URL is provided, render as a link -->
            <ng-container *ngIf="!last && crumb.url; else plainText">
                <a [routerLink]="crumb.url" class="de-dark-link" [title]="crumb.label">
                    {{ (crumb.label | titlecase) | slice:0:maxLength }}{{ crumb.label.length > maxLength ? '...' : '' }}
                </a>
            </ng-container>
            <ng-template #plainText>
        <span class="text-capitalize" [title]="crumb.label">
          {{ (crumb.label | titlecase) | slice:0:maxLength }}{{ crumb.label.length > maxLength ? '...' : '' }}
        </span>
            </ng-template>
        </li>
    </ol>
</nav>
