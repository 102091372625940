import {Injectable, NgZone} from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PendingRequestsService {

  private pendingRequests: HttpRequest<any>[] = []
  private hasPendingRequestsSubject = new BehaviorSubject<boolean>(false);
  private hasPendingRequests: boolean
  readonly hasPendingRequests$ = this.hasPendingRequestsSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private zone: NgZone) { }

  addPendingRequest(request: HttpRequest<any>) {
    this.pendingRequests.push(request);
    this.updateObservables();
  }

  removePendingRequest(request: HttpRequest<any>) {
    setTimeout(() => {
      const index = this.pendingRequests.indexOf(request);
      if (index >= 0) {
        this.zone.run(() => {
          this.pendingRequests.splice(index, 1);
          this.updateObservables();
        });
      }
    }, 300);
  }

  private updateObservables() {
    if (this.hasPendingRequests !== this.pendingRequests.length > 0) {
      this.hasPendingRequests = this.pendingRequests.length > 0;
      this.hasPendingRequestsSubject.next(this.hasPendingRequests);
    }
  }
}
