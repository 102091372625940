import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserLogInResponse } from './interfaces/user-log-in-response.model';
import { UtilityService } from '@modules/utility/services/utility.service';
import { PaytronixHttpService } from './paytronix-http.service';
import { UserLogInRequest } from './interfaces/user-log-in-request.model';
import { CreateUpdateUserRequest } from './interfaces/create-update-user-request.model';
import { CreateUserLogInResponse } from './interfaces/create-user-response.model';
import { CreateAccount } from 'src/interfaces/create-account.interface';
import { UserInfoResponse } from './interfaces/user-info-response.model';
import { UpdateAccount } from './interfaces/update-account.interface';
import { AccountInfoResponse } from './interfaces/account-info-response.model';
import { AccountOffersResponse } from './interfaces/account-offers-response.model';
import { AccountHistoryResponse } from './interfaces/account-history-response.model';
import { AccountRewardsResponse } from './interfaces/account-rewards-response.model';
import { UserCheckinResponse } from './interfaces/user-checkin-response.model';
import { OloSSOGuestTokenRequest } from './interfaces/olo-sso-request.model';
import { Cacheable } from 'ts-cacheable';
import { EditExternalAccountsRequest } from './interfaces/edit-external-accounts-request.interface';
import { CommonEnrollmentServiceResponse } from './interfaces/common-enrollment-service-response.interface';
import { GetNearbyLocationsResponse } from './interfaces/get-nearby-locations-response.interface';
import { CheckInAtStoreRequest } from './interfaces/check-in-at-store-request.interface';
import moment from 'moment-timezone';
import { CheckInAtStoreResponse } from './interfaces/check-in-at-store-response.interface';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { ErrorResponse } from './interfaces/error-response.interface';
import { CalculatePriceResponse } from './interfaces/calculate-price-response.interface';
import { ExecuteLoyaltyCardSaleResponse } from './interfaces/execute-loyalty-card-sale-response.interface';
import { SaleConfigResponse } from './interfaces/sale-config-response.interface';
import { CalculatePriceRequest } from './interfaces/calculate-price-request.interface';
import { ExecuteSaleRequest } from './interfaces/execute-sale-request.interface';
import { SaleConfigForAccountResponse, WebSaleProgramType } from './interfaces/sale-config-for-account.interface';
import { ExecuteSaleForAccountRequest, ExecuteSaleForAccountResponse } from './interfaces/execute-sale-for-account.interface';
import { CalculatePriceForAccountRequest, CalculatePriceForAccountResponse } from './interfaces/calculate-price-for-account.interface';
import { TransferBalanceRequest } from './interfaces/transfer-balance.interface';
import { BalanceTransferServiceError } from './interfaces/balance-transfer-service.interface';
import { TransactionResponse } from './interfaces/transaction-response.interface';
import { RechargeRequest } from './interfaces/recharge-request.interface';
import { RechargeResponse } from './interfaces/recharge-response.interface';
import { UrlEncoder } from '../../models/url-encoder';
import { DirectusService } from '../directus/directus.service';
import { GetNotificationsForGuestByPrintedCardNumberResponse } from './interfaces/get-notifications-for-guest-by-printed-card-number-response.interface';
import { UserField } from '../../interfaces/user-field';
import {
  AddReferralsByPrintedCardNumberRequest,
  AddReferralsByPrintedCardNumberResponse,
} from './interfaces/add-referrals-by-printed-card-number.interface';
import { GetListOfReferralsForUserByPrintedCardNumberResponse } from './interfaces/get-list-referrals-for-user-by-printed-card-number.interface';
import { RetrieveURLToPassbookPassResponse } from './interfaces/apple-passbook-service.model';
import { GenerateSaveToAndroidPayJWTRequest, GenerateSaveToAndroidPayJWTResponse } from './interfaces/google-wallet-service.model';
import { GetRewardDetailsResponse } from './interfaces/get-reward-details.model';
import {
  SaveAndroidPayObjectToLoyaltyAccountRequest,
  SaveAndroidPayObjectToLoyaltyAccountResponse,
} from './interfaces/save-android-pay-object-to-loyalty-account.model';
import {
  SendEmailVerificationChallengeRequest,
  SendEmailVerificationChallengeResponse,
} from './interfaces/send-email-verification-challenge.interface';
import { AddOrRedeemValueFromACardAccountRequest } from './interfaces/add-or-redeem-value-from-a-card-account.interface';
import { AddRedeemReply } from './interfaces/transaction-service-common.interface';
import { DineEngineError } from '../../interfaces/dineengine-error.interface';

@Injectable({
  providedIn: 'root',
})
export class PaytronixAPIService {
  private oauthRedirUrl = '/profile?oauth_vendor=paytronix';
  private loginUrl = '/guest/accountlogin?';

  constructor(
    private apiHttp: PaytronixHttpService,
    private directus: DirectusService,
    private utils: UtilityService
  ) {
    this.directus.getPaytronixSettings().subscribe(() => {});
  }

  isOauthEnabled(): Observable<boolean> {
    return this.directus.getPaytronixSettings().pipe(
      map(res => {
        return res.enable_oauth;
      })
    );
  }

  redirectToOauthPage() {
    this.directus.getPaytronixSettings().subscribe(res => {
      // tslint:disable:variable-name
      const client_id = res.merchant_id;
      const base_url = res.oauth_base_url;
      const redir_url = this.utils.getMyHost() + this.oauthRedirUrl;
      const url =
        base_url +
        this.loginUrl +
        this.utils.queryStringify({
          response_type: 'code',
          redirect_uri: redir_url,
          client_id,
          scope: 'user_read user_write account_read account_write',
          // state: btoa(state)
        });
      // console.log(url);
      this.utils.redirectTo(url);
    });
  }

  @Cacheable({
    maxAge: 1000,
  })
  getUserInfo(authToken: string, username: string): Observable<UserInfoResponse> {
    return this.getMerchID().pipe(
      switchMap(client => {
        const params = new HttpParams({
          fromObject: {
            access_token: authToken,
            username,
            authentication: 'oauth',
            merchantId: client,
          },
          encoder: new UrlEncoder(),
        });
        // params = params
        //   .append('access_token', authToken)
        //   .append('username', new HttpUrlEncodingCodec().encodeValue(username))
        //   .append('authentication', 'oauth')
        //   .append('merchantId', client);
        const resource = '/guest/userInformation.json';
        return this.apiHttp.get<UserInfoResponse>(resource, params);
      })
    );
  }

  @Cacheable({
    maxAge: 1000,
  })
  getUserInfoByCardNumber(printedCardNumber: string): Observable<UserInfoResponse> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        let params = new HttpParams();
        params = params.append('authentication', 'b2b').append('printedCardNumber', printedCardNumber).append('merchantId', merchID);

        const paramsStr = params.keys().length > 0 ? '?' + params.toString() : '';
        const resource = '/guest/userInformationByPrintedCardNumber.json';
        return this.apiHttp.get<UserInfoResponse>(resource + paramsStr);
      })
    );
  }

  updateUser(user: UpdateAccount, additionalFields: UserField[]): Observable<any> {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(config => {
        const body = {
          authentication: 'oauth',
          access_token: user.access_token,
          merchantId: parseInt(config.merchant_id, 10) || 0,
          printedCardNumber: user.card_number,
          enforceUniqueFields: ['email'],
          setUserFields: {
            style: 'typed',
            optIn: user.email_optin,
            firstName: [user.first_name],
            lastName: [user.last_name],
            email: [user.email],
            username: [user.email],
            mobilePhone: [user.phone],
            textCampaignOptIn: user.sms_optin,
            dateOfBirth: [user.date_of_birth],
          },
          setAccountFields: {
            style: 'typed',
            favoriteStore: [{ code: user.location_code }],
          },
        };
        additionalFields.forEach(field => {
          body.setUserFields[field.providerFieldName] = [field.value];
        });
        const resource = '/enrollment/editAccount.json';
        return this.apiHttp.post<any>(resource, body);
      })
    );
  }

  createUser(user: CreateAccount, additionalFields: UserField[]): Observable<UserLogInResponse> {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(config => {
        const body: CreateUpdateUserRequest = {
          authentication: 'b2b',
          merchantId: parseInt(config.merchant_id, 10) || 0,
          cardTemplateCode: parseInt(config.enroll_card_template, 10) || 0,
          enforceUniqueFields: ['email', 'mobilePhone'],
          setUserFields: {
            style: 'typed',
            optIn: user.emailOptIn,
            firstName: [user.firstName],
            lastName: [user.lastName],
            email: [user.email],
            username: [user.email],
            password: [user.password],
            mobilePhone: [user.phone],
            textCampaignOptIn: user.smsOptIn,
          },
          setAccountFields: {
            style: 'typed',
            favoriteStore: user?.favoriteLocation?.locationID ? [{ code: user.favoriteLocation.locationID }] : [],
          },
        };

        if (user.dob) {
          body.setUserFields.dateOfBirth = [user.dob];
        }
        additionalFields.forEach(field => {
          body.setUserFields[field.providerFieldName] = [field.value];
        });

        const resource = '/enrollment/createAndRegister.json';
        return this.apiHttp.post<CreateUserLogInResponse>(resource, body).pipe(
          switchMap(loginResponse => {
            if (!loginResponse.result.toLowerCase().includes('success')) {
              if (loginResponse.result === 'cardCreatedEmailVerificationRequired') {
                return this.sendEmailVerificationChallenge(user.email).pipe(
                  switchMap(() => {
                    const error: DineEngineError = {
                      message:
                        'Your account has been created, but you must verify your email before you can login. Please check your inbox for the verification email',
                      name: 'Waiting for Email Verification',
                    };
                    return throwError(error);
                  })
                );
              }
              return throwError(new HttpErrorResponse({ error: loginResponse }));
            }
            return of(loginResponse.oauthTokens);
          })
        );
      })
    );
  }

  sendEmailVerificationChallenge(email: string): Observable<SendEmailVerificationChallengeResponse> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        const body: SendEmailVerificationChallengeRequest = {
          authentication: 'b2b',
          merchantId: parseInt(merchID, 10) || 0,
          username: email,
        };
        const resource = '/enrollment/sendVerificationEmail.json';
        return this.apiHttp.post<SendEmailVerificationChallengeResponse>(resource, body);
      }),
      map(res => {
        if (res.result === 'failure') {
          throw new Error(res.errorMessage);
        } else {
          return res;
        }
      })
    );
  }

  sendForgotPasswordEmail(email: string): Observable<any> {
    return this.getMerchID().pipe(
      switchMap(clientID => {
        const body = {
          authentication: 'b2b',
          email,
          merchantId: parseInt(clientID, 10) || 0,
        };
        const resource = '/guestmanagement/generateAndSendGuestResetPasswordCode.json';
        return this.apiHttp.post<any>(resource, body).pipe(
          map(res => {
            if (res.result === 'failure' && res.errorCode === 'deliver_code.no_active_accounts') {
              throw new Error('No account found with that email address.');
            } else {
              return res;
            }
          })
        );
      })
    );
  }

  sendResetPasswordEmail(email: string): Observable<any> {
    return this.getMerchID().pipe(
      switchMap(clientID => {
        const body = {
          authentication: 'b2b',
          merchantId: parseInt(clientID, 10) || 0,
          email,
        };
        const resource = '/guestmanagement/generateAndSendGuestResetPasswordCode.json';
        return this.apiHttp.post<any>(resource, body).pipe(
          map(res => {
            if (res.result === 'failure' && res.errorCode === 'deliver_code.no_active_accounts') {
              throw new Error('No account found with that email address.');
            } else {
              return res;
            }
          })
        );
      })
    );
  }

  resetPassword(resetPasswordCode: string, newPassword: string): Observable<any> {
    return this.getMerchID().pipe(
      switchMap(clientID => {
        const body = {
          authentication: 'b2b',
          merchantId: parseInt(clientID, 10) || 0,
          resetPasswordCode,
          newPassword,
        };
        const resource = '/guestmanagement/resetGuestPassword.json';
        return this.apiHttp.post<any>(resource, body);
      })
    );
  }

  logIn(email: string, password: string, cardTemplateCode: number): Observable<UserLogInResponse> {
    return this.getMerchID().pipe(
      switchMap(clientID => {
        const body: UserLogInRequest = {
          authentication: 'b2b',
          username: email,
          password,
          cardTemplateCode,
          grant_type: 'password',
          scope: 'user_read user_write account_read account_write',
          merchantId: parseInt(clientID, 10) || 0,
        };
        const resource = '/oauth/requestGuestToken.json';
        return this.apiHttp.post<UserLogInResponse>(resource, body);
      })
    );
  }

  loginWithToken(token: string, redirectURL: string): Observable<UserLogInResponse> {
    return this.getMerchID().pipe(
      switchMap(clientID => {
        const body: UserLogInRequest = {
          authentication: 'b2b',
          merchantId: Number(clientID),
          grant_type: 'authorization_code',
          scope: 'user_read account_read',
          redirect_uri: redirectURL,
          code: token,
        };
        const resource = '/oauth/requestGuestToken.json';
        return this.apiHttp.post<UserLogInResponse>(resource, body);
      })
    );
  }

  loginWithFacebook(email: string, fbToken: string, fbUserId: string): Observable<UserLogInResponse> {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(config => {
        const body = {
          authentication: 'b2b',
          cardTemplateCode: parseInt(config.enroll_card_template, 10) || 0,
          grant_type: 'http://paytronix.com/oauth/fieldset',
          scope: 'user_read user_write account_read account_write',
          merchantId: parseInt(config.merchant_id, 10) || 0,
          fields: {
            externalAccountCode: fbUserId,
            externalIdentifier: 'xhDE_Ea4QhsfC8h3pUtHJnLZ9lRXYgysJVXnR4XNO0',
            externalAccessToken: fbToken,
          },
        };
        const resource = '/oauth/requestGuestToken.json';
        return this.apiHttp.post<UserLogInResponse>(resource, body);
      })
    );
  }

  connectWithFacebook(cardNumber: string, fbToken: string, fbUserId: string): Observable<any> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        const body = {
          authentication: 'b2b',
          merchantId: Number(merchID),
          printedCardNumber: cardNumber,
          constrainByIntegration: 'xhDE_Ea4QhsfC8h3pUtHJnLZ9lRXYgysJVXnR4XNO0',
          operation: 'set',
          externalAccounts: [
            {
              integration: 'xhDE_Ea4QhsfC8h3pUtHJnLZ9lRXYgysJVXnR4XNO0',
              accountCode: fbUserId,
              accessToken: fbToken,
            },
          ],
        };
        const resource = '/enrollment/editExternalAccounts.json';
        return this.apiHttp.post<CommonEnrollmentServiceResponse>(resource, body);
      })
    );
  }

  loginWithApple(appleResponse: SignInWithAppleResponse): Observable<any> {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(ptxConfig => {
        return this.getMerchID().pipe(
          switchMap(merchantId => {
            const body = {
              authentication: 'b2b',
              grant_type: 'http://paytronix.com/oauth/fieldset',
              merchantId: Number(merchantId),
              scope: 'user_read account_read',
              cardTemplateCode: 0,
              fields: {
                externalAccountCode: appleResponse.response.identityToken,
                externalAccessToken: appleResponse.response.authorizationCode,
                externalIdentifier: ptxConfig.apple_sign_in_integration_id,
              },
            };
            const resource = '/oauth/requestGuestToken.json';
            return this.apiHttp.post<CommonEnrollmentServiceResponse>(resource, body).pipe(
              map(res => {
                return res;
              }),
              catchError(err => {
                return throwError(err);
              })
            );
          })
        );
      })
    );
  }

  signUpWithApple(appleResponse: SignInWithAppleResponse, appIdentifier: string) {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(ptxConfig => {
        return this.getMerchID().pipe(
          switchMap(merchantId => {
            const body = {
              authentication: 'b2b',
              merchantId: Number(merchantId),
              cardTemplateCode: 0,
              activationStoreCode: 'pxweb',
              enforceUniqueFields: [],
              setUserFields: {
                style: 'typed',
                username: [appleResponse.response.email],
                password: ['testPassword'],
                email: [appleResponse.response.email],
                firstName: [appleResponse.response.givenName],
                lastName: [appleResponse.response.familyName],
              },
              setAccountFields: {
                style: 'typed',
                externalAccounts: [
                  {
                    accountCode: appleResponse.response.identityToken,
                    accessToken: appleResponse.response.authorizationCode,
                    integration: ptxConfig.apple_sign_in_integration_id,
                    appIdentifier,
                  },
                ],
              },
            };
            console.log(JSON.stringify(body));
            const resource = '/enrollment/createAndRegister.json';
            return this.apiHttp.post<CommonEnrollmentServiceResponse>(resource, body).pipe(
              map(res => {
                return res.oauthTokens;
              })
            );
          })
        );
      })
    );
  }

  connectWithApple(appleResponse: SignInWithAppleResponse, appIdentifier: string, cardNumber: string) {
    return this.directus.getPaytronixSettings().pipe(
      switchMap(ptxConfig => {
        return this.getMerchID().pipe(
          switchMap(merchID => {
            const body = {
              authentication: 'b2b',
              merchantId: Number(merchID),
              printedCardNumber: cardNumber,
              constrainByIntegration: ptxConfig.apple_sign_in_integration_id,
              operation: 'set',
              externalAccounts: [
                {
                  accountCode: appleResponse.response.identityToken,
                  accessToken: appleResponse.response.authorizationCode,
                  integration: ptxConfig.apple_sign_in_integration_id,
                  appIdentifier,
                },
              ],
            };
            const resource = '/enrollment/editExternalAccounts.json';
            return this.apiHttp.post<CommonEnrollmentServiceResponse>(resource, body).pipe(
              map(res => {
                return res.oauthTokens;
              })
            );
          })
        );
      })
    );
  }

  logInByRefreshToken(token: string): Observable<UserLogInResponse> {
    return this.getMerchID().pipe(
      switchMap(clientID => {
        const body: UserLogInRequest = {
          authentication: 'b2b',
          refresh_token: token,
          grant_type: 'refresh_token',
          scope: 'user_read user_write account_read account_write',
          merchantId: parseInt(clientID, 10) || 0,
        };
        const resource = '/oauth/requestGuestToken.json';
        return this.apiHttp.post<UserLogInResponse>(resource, body);
      })
    );
  }

  // loginWithFacebook(email: string, fbToken: string, fbUserId: string): Observable<UserLogInResponse> {
  //   return this.directus.getPaytronixConfiguration().pipe(switchMap(config => {
  //     const body = {
  //       authentication: 'anonymous',
  //       cardTemplateCode: parseInt(config.data.enroll_card_template, 10) || 0,
  //       grant_type: 'http://paytronix.com/oauth/fieldset',
  //       scope: 'user_read user_write account_read account_write',
  //       merchantId: parseInt(config.data.merchant_id, 10) || 0,
  //       fields: {
  //         externalAccountCode: fbUserId,
  //         externalIdentifier: 'xhDE_Ea4QhsfC8h3pUtHJnLZ9lRXYgysJVXnR4XNO0',
  //         externalAccessToken: fbToken
  //       }
  //     };
  //     const resource = '/oauth/requestGuestToken.json';
  //     return this.apiHttp.post<UserLogInResponse>(resource, body);
  //   }));
  // }
  //
  // connectWithFacebook(cardNumber: string, fbToken: string, fbUserId: string): Observable<UserLogInResponse> {
  //   return this.getMerchID().pipe(switchMap(merchID => {
  //     const body = {
  //       authentication: 'b2b',
  //       merchantId: Number(merchID),
  //       printedCardNumber: cardNumber,
  //       constrainByIntegration: 'xhDE_Ea4QhsfC8h3pUtHJnLZ9lRXYgysJVXnR4XNO0',
  //       operation: 'set',
  //       externalAccounts: [
  //         {
  //           integration: 'xhDE_Ea4QhsfC8h3pUtHJnLZ9lRXYgysJVXnR4XNO0',
  //           accountCode: fbUserId,
  //           accessToken: fbToken
  //         }
  //       ]
  //     };
  //     const resource = '/enrollment/editExternalAccounts.json';
  //     return this.apiHttp.post<CommonEnrollmentServiceResponse>(resource, body).pipe(map(res => {
  //       return res.oauthTokens;
  //     }));
  //   }));
  // }
  //
  // loginWithApple(appleResponse: SignInWithAppleResponse): Observable<UserLogInResponse> {
  //   return this.directus.getPaytronixConfiguration().pipe(switchMap(ptxConfig => {
  //     return this.getMerchID().pipe(switchMap(merchantId => {
  //       const body = {
  //         authentication: 'anonymous',
  //         grant_type: 'http://paytronix.com/oauth/fieldset',
  //         merchantId: Number(merchantId),
  //         scope: 'user_read account_read',
  //         fields: {
  //           externalAccountCode: appleResponse.response.identityToken,
  //           externalAccessToken: appleResponse.response.authorizationCode,
  //           externalIdentifier: ptxConfig.data.apple_sign_in_integration_id
  //         }
  //       };
  //       console.log(JSON.stringify(body))
  //       const resource = '/oauth/requestGuestToken.json';
  //       return this.apiHttp.post<CommonEnrollmentServiceResponse>(resource, body).pipe(map(res => {
  //         return res.oauthTokens
  //       }));
  //     }));
  //   }));
  // }
  //
  // signUpWithApple(appleResponse: SignInWithAppleResponse, appIdentifier: string) {
  //   return this.directus.getPaytronixConfiguration().pipe(switchMap(ptxConfig => {
  //     return this.getMerchID().pipe(switchMap(merchantId => {
  //       const body = {
  //         authentication: 'b2b',
  //         merchantId: Number(merchantId),
  //         cardTemplateCode: 0,
  //         activationStoreCode: 'pxweb',
  //         enforceUniqueFields: [],
  //         setUserFields: {
  //           style: 'typed',
  //           username: ['testUsername'],
  //           password: ['testPassword'],
  //           email: [appleResponse.response.email],
  //           firstName: [appleResponse.response.givenName],
  //           lastName: [appleResponse.response.familyName]
  //         },
  //         setAccountFields: {
  //           style: 'typed',
  //           externalAccounts: [
  //             {
  //               accountCode: appleResponse.response.identityToken,
  //               accessToken: appleResponse.response.authorizationCode,
  //               integration: ptxConfig.data.apple_sign_in_integration_id,
  //               appIdentifier: appIdentifier
  //             }
  //           ]
  //         }
  //       };
  //       console.log(JSON.stringify(body))
  //       const resource = '/enrollment/createAndRegister.json';
  //       return this.apiHttp.post<CommonEnrollmentServiceResponse>(resource, body).pipe(map(res => {
  //         return res.oauthTokens
  //       }));
  //     }));
  //   }));
  // }
  //
  // connectWithApple(appleResponse: SignInWithAppleResponse, appIdentifier: string, cardNumber: string) {
  //   return this.directus.getPaytronixConfiguration().pipe(map(res => res.data), switchMap((ptxConfig) => {
  //     return this.getMerchID().pipe(switchMap(merchID => {
  //       const body = {
  //         authentication: 'b2b',
  //         merchantId: Number(merchID),
  //         printedCardNumber: cardNumber,
  //         constrainByIntegration: ptxConfig.apple_sign_in_integration_id,
  //         operation: 'set',
  //         externalAccounts: [
  //           {
  //             accountCode: appleResponse.response.identityToken,
  //             accessToken: appleResponse.response.authorizationCode,
  //             integration: ptxConfig.apple_sign_in_integration_id,
  //             appIdentifier: appIdentifier
  //           }
  //         ]
  //       };
  //       const resource = '/enrollment/editExternalAccounts.json';
  //       return this.apiHttp.post<CommonEnrollmentServiceResponse>(resource, body).pipe(map(res => {
  //         return res.oauthTokens;
  //       }));
  //     }))
  //   }))
  // }

  getOloSSOToken(token: string, cardNumber: string): Observable<UserLogInResponse> {
    return this.getMerchID().pipe(
      switchMap(client => {
        const body: OloSSOGuestTokenRequest = {
          authentication: 'oauth',
          printedCardNumber: cardNumber,
          access_token: token,
          merchantId: parseInt(client, 10) || 0,
        };
        const resource = '/olo/requestOloGuestToken.json';
        return this.apiHttp.post<UserLogInResponse>(resource, body);
      })
    );
  }

  @Cacheable({
    maxAge: 30000,
  })
  getAccountInfo(authToken: string, accountId: string): Observable<AccountInfoResponse> {
    return this.getMerchID().pipe(
      switchMap(client => {
        let params = new HttpParams();
        params = params
          .append('access_token', authToken)
          .append('accountId', accountId)
          .append('authentication', 'oauth')
          .append('merchantId', client);

        const paramsStr = params.keys().length > 0 ? '?' + params.toString() : '';
        const resource = '/guest/accountInformationById.json';
        return this.apiHttp.get<AccountInfoResponse>(resource + paramsStr);
      })
    );
  }

  editExternalAccounts(request: EditExternalAccountsRequest): Observable<CommonEnrollmentServiceResponse> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        const body = {
          merchantId: merchID,
          authentication: 'b2b',
          ...request,
        };
        const resource = '/enrollment/editExternalAccounts.json';
        return this.apiHttp.post<CommonEnrollmentServiceResponse>(resource, body);
      })
    );
  }

  getAccountOffers(authToken: string, accountId: string): Observable<AccountOffersResponse> {
    return this.getMerchID().pipe(
      switchMap(client => {
        let params = new HttpParams();
        params = params
          .append('access_token', authToken)
          .append('accountId', accountId)
          .append('authentication', 'oauth')
          .append('merchantId', client);

        const paramsStr = params.keys().length > 0 ? '?' + params.toString() : '';
        const resource = '/guest/pendingEventRewardsByAccountId.json';
        return this.apiHttp.get<AccountOffersResponse>(resource + paramsStr);
      })
    );
  }

  getAccountHistory(authToken: string, accountId: string): Observable<AccountHistoryResponse> {
    return this.getMerchID().pipe(
      switchMap(client => {
        let params = new HttpParams();
        params = params
          .append('access_token', authToken)
          .append('accountId', accountId)
          .append('authentication', 'oauth')
          .append('merchantId', client);

        const paramsStr = params.keys().length > 0 ? '?' + params.toString() : '';
        const resource = '/guest/transactionHistoryByAccount.json';
        return this.apiHttp.get<AccountHistoryResponse>(resource + paramsStr);
      })
    );
  }

  createUserCheckin(authToken: string, cardNumber: string, checkinTime: Date): Observable<UserCheckinResponse> {
    return this.getMerchID().pipe(
      switchMap(client => {
        let params = new HttpParams();
        const account = JSON.stringify({ printedCardNumber: cardNumber });
        params = params
          .append('access_token', authToken)
          .append('account', account)
          .append('checkinTime', checkinTime.toUTCString())
          .append('authentication', 'oauth')
          .append('merchantId', client);
        const paramsStr = params.keys().length > 0 ? '?' + params.toString() : '';
        const resource = 'checkin/checkinAccount.json';
        return this.apiHttp.get<UserCheckinResponse>(resource + paramsStr);
      })
    );
  }

  getAccountRewards(authToken: string, accountId: string): Observable<AccountRewardsResponse> {
    return this.getMerchID().pipe(
      switchMap(client => {
        let params = new HttpParams();
        params = params
          .append('access_token', authToken)
          .append('accountId', accountId)
          .append('authentication', 'b2b')
          .append('merchantId', client);

        const paramsStr = params.keys().length > 0 ? '?' + params.toString() : '';
        const resource = '/guest/pendingEventRewardsByAccountId.json';
        return this.apiHttp.get<AccountRewardsResponse>(resource + paramsStr);
      })
    );
  }

  getNearbyLocations(
    latitude: number,
    longitude: number,
    maxDistance: number,
    maxLocations: number
  ): Observable<GetNearbyLocationsResponse> {
    return this.getMerchID().pipe(
      switchMap(merchId => {
        let params = new HttpParams();
        params = params
          .append('merchantId', merchId)
          .append('latitude', String(latitude))
          .append('longitude', String(longitude))
          .append('maxDistance', String(maxDistance))
          .append('maxLocations', String(maxLocations))
          .append('authentication', 'b2b');
        const paramsStr = params.keys().length > 0 ? '?' + params.toString() : '';
        const resource = '/store/nearbyLocations.json';
        return this.apiHttp.get<GetNearbyLocationsResponse>(resource + paramsStr);
      })
    );
  }

  getLocations(): Observable<GetNearbyLocationsResponse> {
    return this.getMerchID().pipe(
      switchMap(merchId => {
        let params = new HttpParams();
        params = params.append('merchantId', merchId).append('authentication', 'anonymous');
        const paramsStr = params.keys().length > 0 ? '?' + params.toString() : '';
        const resource = '/store/stores.json';
        return this.apiHttp.get<GetNearbyLocationsResponse>(resource + paramsStr);
      })
    );
  }

  getLocationsByGroup(groupCode: string): Observable<GetNearbyLocationsResponse> {
    return this.getMerchID().pipe(
      switchMap(merchId => {
        let params = new HttpParams();
        params = params.append('merchantId', merchId).append('storeGroupCode', groupCode).append('authentication', 'anonymous');
        const paramsStr = params.keys().length > 0 ? '?' + params.toString() : '';
        const resource = '/store/storesByStoreGroup.json';
        return this.apiHttp.get<GetNearbyLocationsResponse>(resource + paramsStr);
      })
    );
  }

  checkInAtStore(storeCode: string, account: any): Observable<CheckInAtStoreResponse> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        const body: CheckInAtStoreRequest = {
          authentication: 'b2b',
          merchantId: Number(merchID),
          storeCode,
          checkinTime: moment().format('YYYY-MM-DD HH:mm:ss ZZ'),
          account,
        };
        const resource = '/checkin/checkinAccount.json';
        return this.apiHttp.post<CheckInAtStoreResponse>(resource, body);
      })
    );
  }

  // In your apiService

  createQuickCode(storeCode: string | null, account: any): Observable<CheckInAtStoreResponse> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        const body: CheckInAtStoreRequest = {
          authentication: 'b2b',
          merchantId: Number(merchID),
          checkinTime: moment().format('YYYY-MM-DD HH:mm:ss ZZ'),
          account,
        };
        // Include storeCode only if it's provided
        if (storeCode) {
          body.storeCode = storeCode;
        }
        const resource = '/checkin/checkinAccount.json';
        return this.apiHttp.post<CheckInAtStoreResponse>(resource, body);
      })
    );
  }

  getGiftCardSaleConfig(flowType: string): Observable<SaleConfigResponse | ErrorResponse> {
    // TODO: make the model
    return this.getMerchID().pipe(
      switchMap(merchID => {
        let params = new HttpParams();
        params = params.append('authentication', 'b2b').append('merchantId', merchID).append('programType', flowType);
        const paramsStr = params.keys().length > 0 ? '?' + params.toString() : '';
        const resource = '/sale/saleConfig.json';
        return this.apiHttp.get<SaleConfigResponse | ErrorResponse>(resource + paramsStr); // TODO: make the model
      })
    );
  }

  calculateGiftCardPrice(body: CalculatePriceRequest): Observable<CalculatePriceResponse | ErrorResponse> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        body.authentication = 'b2b';
        body.merchantId = Number(merchID);
        const resource = '/sale/calculatePrice.json';
        return this.apiHttp.post<CalculatePriceResponse | ErrorResponse>(resource, body);
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  executeGiftCardSale(body: ExecuteSaleRequest): Observable<ExecuteLoyaltyCardSaleResponse | ErrorResponse> {
    // TODO: make the model
    return this.getMerchID().pipe(
      switchMap(merchID => {
        body.authentication = 'b2b';
        body.merchantId = Number(merchID);
        const resource = '/sale/executeSale.json';
        return this.apiHttp.post<ExecuteLoyaltyCardSaleResponse | ErrorResponse>(resource, body);
      })
    );
  }

  getGiftCardBalance(cardNumber: string, pin: string): Observable<any | ErrorResponse> {
    // TODO: make the model
    return this.getMerchID().pipe(
      switchMap(merchID => {
        const body: any = {
          // TODO: make the model
          authentication: 'b2b',
          headerInfo: {
            merchantId: Number(merchID),
            storeCode: 'corp',
            operatorId: '0',
            senderId: 'POS',
            programId: 'PX',
            terminalId: '0',
          },
          cardInfo: {
            swipeFlag: false,
            printedCardNumber: cardNumber,
            cardRegCode: pin,
          },
          // promotionCode, // Do not use, ever?!?
        };
        const resource = '/transaction/balanceInquiry.json';
        return this.apiHttp.post<any | ErrorResponse>(resource, body);
      })
    );
  }

  getTransactionHistory(cardNumber: string, pin: string, dateStart: string): Observable<TransactionResponse> {
    // TODO: make the model
    return this.getMerchID().pipe(
      switchMap(merchID => {
        const body: any = {
          // TODO: make the model
          authentication: 'b2b',
          headerInfo: {
            merchantId: Number(merchID),
            storeCode: 'corp',
            operatorId: '0',
            senderId: 'POS',
            programId: 'PX',
            terminalId: '0',
          },
          cardInfo: {
            swipeFlag: false,
            printedCardNumber: cardNumber,
            cardRegCode: pin,
          },
          dateStart,
          // promotionCode, // Do not use, ever?!?
        };
        const resource = '/transaction/transactionHistory.json';
        return this.apiHttp.post<TransactionResponse | any>(resource, body);
      })
    );
  }

  getSaleConfigurationForAccount(programType: WebSaleProgramType, printedCardNumber: string): Observable<SaleConfigForAccountResponse> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        // const body: SaleConfigForAccountRequest = ;
        const params = new HttpParams({
          fromObject: {
            merchantId: merchID,
            programType,
            printedCardNumber,
          },
        });
        const resource = '/sale/saleConfigForAccount.json';
        return this.apiHttp.get<SaleConfigForAccountResponse>(resource + '?' + params.toString());
      })
    );
  }

  calculatePriceForAccount(body: CalculatePriceForAccountRequest): Observable<CalculatePriceForAccountResponse> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        body.merchantId = Number(merchID);
        const resource = '/sale/calculatePriceForAccount.json';
        return this.apiHttp.post<CalculatePriceForAccountResponse>(resource, body);
      })
    );
  }

  executeSaleForAccount(body: ExecuteSaleForAccountRequest): Observable<ExecuteSaleForAccountResponse> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        body.merchantId = Number(merchID);
        const resource = '/sale/executeSaleForAccount.json';
        return this.apiHttp.post<ExecuteSaleForAccountResponse>(resource, body);
      })
    );
  }

  transferBalances(fromCardNumber: string, fromRegCode: string, toCardNumber: string): Observable<{ result: 'success' | 'failure' }> {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        const body: TransferBalanceRequest = {
          merchantId: Number(merchID),
          printedCardNumber: toCardNumber,
          fromPrintedCardNumber: fromCardNumber,
          fromRegCode,
          mustTransferAllWalletBalances: true,
        };
        return this.apiHttp.post<{ result: 'success' | 'failure' }>('/balancetransfer/transferBalances.json', body).pipe(
          switchMap(res => {
            if (res.result === 'success') {
              return of(res);
            } else {
              return throwError(res as BalanceTransferServiceError);
            }
          })
        );
      })
    );
  }

  rechargeGiftCard(body: RechargeRequest) {
    return this.getMerchID().pipe(
      switchMap(merchID => {
        body.merchantId = Number(merchID);
        const resource = '/payment/recharge.json';
        return this.apiHttp.post<RechargeResponse>(resource, body);
      })
    );
  }

  deleteAccount(cardNumber: string) {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        let params = new HttpParams();
        params = params.append('authentication', 'b2b').append('merchantId', merchID).append('printedCardNumber', cardNumber);
        const resource = '/enrollment/userByPrintedCardNumber.json';
        return this.apiHttp.delete<RechargeResponse>(resource, params);
      })
    );
  }

  applyVisitCode(code: string, accountId: string, cardNumber: string) {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        const body: any = {
          authentication: 'b2b',
          merchantId: Number(merchID),
          printedCardNumber: cardNumber,
          accountId,
          code,
        };
        const resource = '/guest/applyVisitCode.json';
        return this.apiHttp.post<any>(resource, body);
      })
    );
  }

  addReferralsByPrintedCardNumber(
    cardNumber: string,
    emails: string[],
    message: string
  ): Observable<AddReferralsByPrintedCardNumberResponse> {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        const body: AddReferralsByPrintedCardNumberRequest = {
          authentication: 'b2b',
          merchantId: Number(merchID),
          printedCardNumber: cardNumber,
          emails,
          message,
        };
        const resource = '/guest/addReferralsByPrintedCardNumber.json';
        return this.apiHttp.post<AddReferralsByPrintedCardNumberResponse>(resource, body).pipe(
          map(res => {
            if (res.result === 'failure') {
              throw new Error(res['errorMessage']);
            }
            return res;
          })
        );
      })
    );
  }

  getListOfReferralsForUserByPrintedCardNumber(cardNumber: string): Observable<GetListOfReferralsForUserByPrintedCardNumberResponse> {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        const params = new HttpParams({
          fromObject: {
            authentication: 'b2b',
            merchantId: merchID,
            printedCardNumber: cardNumber,
          },
        });
        const resource = '/guest/referralsByPrintedCardNumber.json';
        return this.apiHttp.get<GetListOfReferralsForUserByPrintedCardNumberResponse>(resource, params);
      })
    );
  }

  getNotificationsForGuestByPrintedCardNumber(cardNumber: string): Observable<GetNotificationsForGuestByPrintedCardNumberResponse> {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        const params = new HttpParams({
          fromObject: {
            authentication: 'b2b',
            merchantId: merchID,
            printedCardNumber: cardNumber,
          },
        });
        const resource = '/message/myMessagesByPrintedCardNumber.json';
        return this.apiHttp.get<GetNotificationsForGuestByPrintedCardNumberResponse>(resource, params);
      })
    );
  }

  deleteNotificationForGuestByPrintedCardNumber(cardNumber: string, messageCode: string): Observable<void> {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        const params = new HttpParams({
          fromObject: {
            authentication: 'b2b',
            merchantId: merchID,
            printedCardNumber: cardNumber,
            messageCode,
          },
        });
        const resource = '/message/deleteMessageByPrintedCardNumber.json';
        return this.apiHttp.delete<void>(resource, params);
      })
    );
  }

  // Apple Wallet
  retrieveURLtoPassbookPass(cardNumber: string): Observable<RetrieveURLToPassbookPassResponse> {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        const params = new HttpParams({
          fromObject: {
            authentication: 'b2b',
            merchantId: merchID,
            printedCardNumber: cardNumber,
          },
        });
        const resource = '/applepassbook/passbookUrl.json';
        return this.apiHttp.get<RetrieveURLToPassbookPassResponse>(resource, params);
      })
    );
  }

  // Google Wallet

  generateSaveToAndroidPayJWT(cardNumber: string) {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        const body: GenerateSaveToAndroidPayJWTRequest = {
          authentication: 'b2b',
          merchantId: Number(merchID),
          printedCardNumber: cardNumber,
          url: document.location.href,
        };
        const resource = '/googlewallet/generateSaveToAndroidPayJWT.json';
        return this.apiHttp.post<GenerateSaveToAndroidPayJWTResponse>(resource, body);
      })
    );
  }

  saveAndroidPayObjectToLoyaltyAccount(cardNumber: string, objectCode: string): Observable<SaveAndroidPayObjectToLoyaltyAccountResponse> {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        const resource = '/googlewallet/saveAndroidPayObjectToLoyaltyAccount.json';
        const body: SaveAndroidPayObjectToLoyaltyAccountRequest = {
          merchantId: merchID,
          printedCardNumber: cardNumber,
          code: objectCode,
        };
        return this.apiHttp.post<SaveAndroidPayObjectToLoyaltyAccountResponse>(resource, body);
      })
    );
  }

  @Cacheable({
    maxAge: 1000 * 60 * 5,
    maxCacheCount: 10,
  })
  getRewardDetails(storeCode: string, walletCodes: number[]): Observable<GetRewardDetailsResponse> {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        const params = new HttpParams({
          fromObject: {
            authentication: 'b2b',
            merchantId: Number(merchID),
            storeCode,
            'walletCodes[]': walletCodes,
          },
        });
        const resource = '/check/rewardDetails.json';
        return this.apiHttp.get<GetRewardDetailsResponse>(resource, params);
      })
    );
  }

  addOrRedeemValueFromACardAccount(body: AddOrRedeemValueFromACardAccountRequest): Observable<AddRedeemReply> {
    return this.getMerchID().pipe(
      switchMap((merchID: string) => {
        body.headerInfo.merchantId = Number(merchID);
        body.authentication = 'b2b';
        const resource = '/transaction/addRedeem.json';
        return this.apiHttp.post<AddRedeemReply>(resource, body);
      })
    );
  }

  private getMerchID(): Observable<string> {
    return this.directus.getPaytronixSettings().pipe(map(res => res.merchant_id));
  }
}
