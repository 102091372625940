import { Component, Input, HostListener, OnInit } from '@angular/core';

export interface Breadcrumb {
    label: string;
    url?: string;
}

@Component({
    selector: 'app-menu-breadcrumbs',
    templateUrl: './menu-breadcrumbs.component.html',
    styleUrls: ['./menu-breadcrumbs.component.scss']
})
export class MenuBreadcrumbsComponent implements OnInit {
    @Input() breadcrumbs: Breadcrumb[];

    // Set default max length
    maxLength: number = 24;

    ngOnInit(): void {
        this.setMaxLength(window.innerWidth);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.setMaxLength(event.target.innerWidth);
    }

    private setMaxLength(width: number) {
        // Bootstrap's small breakpoint is 576px.
        // Use 14 characters for sizes below 576px, otherwise 20 characters.
        this.maxLength = width < 576 ? 14 : 24;
    }
}
