<ng-container *ngIf="vendorSetup$ | async as vendorSetup">
  <ng-container *ngIf="!mobileService.isMobile">
    <div class="d-flex flex-column gap-1">
      <!-- Dashboard Button -->
      <button
        type="button"
        class="btn border-0 text-capitalize text-start fw-bold"
        [routerLink]="profilePageRoute"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLinkActive]="['btn-primary', 'fw-bold']"
        [ngClass]="{ 'btn-outline-primary': !rlaDashboard.isActive }"
        #rlaDashboard="routerLinkActive"
        id="cyDashboardTab">
        Dashboard
      </button>

      <!-- Loyalty Sections Buttons -->
      <ng-container *ngIf="loyaltySections$ | async as loyaltySections">
        <ng-container *ngFor="let section of loyaltySections">
          <!-- Exclude 'inbox' section since it's handled separately -->
          <ng-container *ngIf="shouldDisplaySection(section) && section.section_type !== 'inbox'">
            <button
              [attr.data-cy-section]="section.section_type"
              type="button"
              class="btn border-0 text-capitalize text-start fw-bold"
              [routerLink]="getRouteForSection(section)"
              [routerLinkActiveOptions]="{ exact: true }"
              [routerLinkActive]="['btn-primary']"
              [ngClass]="{ 'btn-outline-primary': !isActiveRoute(getRouteForSection(section)) }">
              {{ section.section_title }}
            </button>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Inbox Button -->
      <ng-container *ngIf="inboxMessages$ | async">
        <button
          type="button"
          class="btn border-0 text-capitalize text-start fw-bold"
          [routerLink]="inboxRoute"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLinkActive]="['btn-primary']"
          [ngClass]="{ 'btn-outline-primary': !rlaInbox.isActive }"
          #rlaInbox="routerLinkActive">
          Inbox
          <ion-badge mode="ios" class="ms-auto text-bg-danger" slot="end" *ngIf="(inboxMessages | unreadMessage) > 0">
            {{ (inboxMessages | unreadMessage) > 99 ? '99+' : inboxMessages | unreadMessage }}
          </ion-badge>
        </button>
      </ng-container>
    </div>
  </ng-container>

  <!-- Mobile Version -->
  <ng-container *ngIf="mobileService.isMobile">
    <ion-list>

      <!-- Loyalty Sections Items -->
      <ng-container *ngIf="loyaltySections$ | async as loyaltySections">
        <ng-container *ngFor="let section of loyaltySections">
          <!-- Exclude 'inbox' section since it's handled separately -->
          <ng-container *ngIf="shouldDisplaySection(section) && section.section_type !== 'inbox'">
            <ion-item button detail="true" [routerLink]="getRouteForSection(section)" [attr.data-cy-section]="section.section_type">
              <ion-label class="py-1 w-100">
                <div class="d-flex flex-row flex-nowrap w-100 align-items-center fw-bold">
                  <i class="bi bi-{{section.section_icon}} button-icon color-primary d-flex me-2"></i>
                  {{ section.section_title }}
                </div>
              </ion-label>
            </ion-item>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- Inbox Item -->
      <ng-container *ngIf="inboxMessages$ | async as inboxMessages">
        <ion-item button (click)="routeToInbox()">
          <ion-label class="py-1 w-100">
            <div class="d-flex flex-row flex-nowrap w-100 align-items-center fw-bold">
              <i class="bi bi-envelope button-icon color-primary d-flex me-2"></i>
              Inbox
              <ion-badge mode="ios" class="ms-auto text-bg-danger" slot="end" *ngIf="(inboxMessages | unreadMessage) > 0">
                {{ (inboxMessages | unreadMessage) > 99 ? '99+' : inboxMessages | unreadMessage }}
              </ion-badge>
            </div>
          </ion-label>
        </ion-item>
      </ng-container>
    </ion-list>
  </ng-container>
</ng-container>
