<div id="instafeed">
  <ng-container *ngIf="images.length > 0">
    <div class="container w-100 my-5">
      <ng-container *ngIf="mainSettings">
        <div class="w-100 text-center header-font" style="font-size: 40px">Follow {{mainSettings.title | titlecase}} on Instagram</div>
        <div class="my-5" style="display: grid; place-items: center start; grid-template-columns: 98px 1fr; grid-gap: 16px">
          <img (click)="routeToProfile(images[0].username)" class="pointer" [src]="profileImage ? profileImage : 'assets/img/600x600.png'" style="height: 98px; width: 98px; border-radius: 50%" alt="{{mainSettings.title | titlecase}}" >
          <div>
            <div (click)="routeToProfile(images[0].username)" class="pointer">{{mainSettings.title | titlecase}}</div>
            <div (click)="routeToProfile(images[0].username)" class="pointer">&#64;{{images[0].username}}</div>
          </div>
        </div>
      </ng-container>
      <carousel
          [arrowsOutside]="true"
          [cellWidth]="250"
          [height]="282"
          [margin]="16"
          objectFit="cover"
          [loop]="true"
          [arrows]="!isMobile"
      >
        <ng-container *ngFor="let image of images">
          <div class="h-100 py-3 carousel-cell">
            <img class="insta-img"
                 [src]="image.media_type === 'VIDEO' ? image.thumbnail_url : image.media_url"
                 [alt]="image.username"
                 (click)="routeToImage(image.permalink)"
            >
          </div>
        </ng-container>
      </carousel>
    </div>
  </ng-container>
</div>

